<template>
  <div class="container">
    <div class="tip-box">
      FEP ウォレット選択画面 <br />
      シミュレーション用
    </div>
    <div class="main-left">
      <van-radio-group v-model="brandType">
        <van-cell-group title="Selection payment method">
          <van-cell title="" clickable @click="brandType = '02'">
            <template #title>
              <div class="flex">
                <img :src="wechatPay.logo" />
              </div>
            </template>
            <template #right-icon>
              <van-radio name="02" />
            </template>
          </van-cell>
          <van-cell title="" clickable @click="brandType = '91'">
            <template #title>
              <div class="flex">
                <img :src="ailpayLogo" />
              </div>
            </template>
            <template #right-icon>
              <van-radio name="91" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="main-right">
      <van-card
        num="1"
        origin-price="12"
        price="9"
        desc="Goods descript"
        title="Goods Title"
        thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
      />
      <van-cell-group style="margin: 20px 0">
        <van-cell title="Goods Price" value="JPY 8" />
        <van-cell title="Shipping Price" value="JPY 1" />
        <van-cell title="Tax" value="JPY 1" />
        <van-cell title="Total" :value="`JPY ${orderPrice}`" />
        <!-- <template v-if="isCVS">
          <van-field
            v-model="form.name"
            label="Name"
            maxlength="20"
            input-align="right"
            placeholder="Please iput name"
          />
          <van-field
            v-model="form.surname"
            label="Surname"
            maxlength="20"
            input-align="right"
            placeholder="Please iput surname"
          />
          <van-field
            v-model="form.tel"
            label="Tel"
            type="tel"
            maxlength="11"
            input-align="right"
            placeholder="Please iput phone"
          />
          <van-field
            v-model="form.mail"
            label="Mail"
            maxlength="128"
            type="mail"
            input-align="right"
            placeholder="Please iput E-mail"
          />
        </template> -->
      </van-cell-group>
      <van-button type="primary" block :loading="loading" @click="handlePay">
        Continue to pay
      </van-button>
    </div>

    <div v-if="visible" class="modal">
      <div class="modal-body">
        <a class="close" @click="handleClose"><van-icon name="cross" /></a>
        <div class="qr-left">
          <p class="amount">
            JPY <span class="blue">{{ orderPrice }}</span>
          </p>
          <vue-qr
            :logoSrc="brandType === '02' ? '' : logo"
            :text="codeUrl"
            :size="240"
          />
          <div class="time">
            QR code will expire in
            <van-icon name="underway-o" />
            <van-count-down :time="time" format="mm min ss s" />
          </div>
        </div>

        <div class="qr-right">
          <h4>You can scan and pay using:</h4>
          <div class="flex">
            <div v-if="brandType === '02'" class="logos">
              <img :src="wechatPay.logo" />
            </div>
          </div>
          <small
            >(Download and use any of these supported wallet apps to pay)</small
          >

          <p>How to use:</p>
          <ul>
            <li>1. Open your wallet app on your device</li>
            <li>2. Tap on the “Scan” feature</li>
            <li>3. Scan QR code on the left to complete payment</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import store from '@/store'
import { openCreate, openConsult, openGet, feqCocos } from '@/apis'
import logo from '@/assets/logo.svg'
import wechatImg from '@/assets/wechatpay.png'
import ailpayLogo from '@/assets/Alipay+.svg'

import { CVS, TELBILL, osType } from '@/utils'

export default {
  name: 'Index',
  components: { VueQr },
  data() {
    return {
      logo,
      visible: false,
      loading: false,
      brandType: '',
      presentmentMode: '',
      ailpayLogo,
      orderPrice: 10,
      expireTime: '600',
      brands: [],
      time: 10 * 60 * 1000,
      codeUrl: '',
      timer: null,
      form: {},
      wechatPay: {
        walletBrandName: 'WeChat Pay',
        value: 'wechat',
        logo: wechatImg,
      },
      merchantId: '82137',
      storeId: '001',
      promos: '',
    }
  },
  beforeRouteEnter(to, from, next) {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|Mobile|SymbianOS|Windows Phone|electron/i.test(navigator.userAgent)
    ) {
      next({
        path: '/fep/m',
        query: {
          ...to.query,
        },
      })
    } else {
      next()
    }
  },
  created() {
    this.initForm()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    initForm() {
      // const { brandType, orderPrice, merchantId, storeId } = this
      const { brandType } = this
      const merchantId = this.$route.query.mid || '82137'
      const storeId = this.$route.query.sid || '001'
      const time = isNaN(this.$route.query.time) ? 10 : this.$route.query.time
      const orderPrice = isNaN(this.$route.query.price) ? 10 : this.$route.query.price
      this.orderPrice = orderPrice
      const expireTime = (time * 60).toString()
      const currency = 'JPY'
      const clientOrderNo = new Date().getTime()
      this.form = {
        brandType,
        cancelUrl: process.env.VUE_APP_BASE2 + '/#/cancel',
        // process.env.VUE_APP_BASE2 +
        // '/#/fep/result?clientOrderNo=' +
        // clientOrderNo,
        clientOrderNo,
        createAt: new Date().getTime(),
        currency,
        description: '',
        expireTime,
        merchantId,
        notifyUrl:
          'https://stgpscode.sbpayment.jp/api/pay/commonTestNotify/index',
        // process.env.VUE_APP_BASE2 +
        // '/#/fep/result?clientOrderNo=' +
        // clientOrderNo,
        orderMeta: {
          products: [
            {
              id: '1',
              name: 'テスト 商品1',
              price: 8,
              quantity: 1,
            },
            {
              id: '2',
              name: 'テスト 商品2',
              price: 2,
              quantity: 1,
            },
          ],
        },
        orderPrice,
        payType: '02',
        redirectUrl: process.env.VUE_APP_BASE2 + '/#/pending?clientOrderNo=' + clientOrderNo,
        // process.env.VUE_APP_BASE2 +
        // '/#/fep/result?clientOrderNo=' +
        // clientOrderNo,
        storeId,
      }
    },
    async handlePay() {
      this.initForm()
      // if (!this.brandType) {
      //   this.$toast.fail('Please select a payment method')
      //   return
      // }
      feqCocos(this.form)
      this.loading = false
    },
    getResult() {
      const clientOrderNo = localStorage.getItem('clientOrderNo') || ''
      openGet({ clientOrderNo }).then((data) => {
        if (data.orderStatus !== 0) {
          this.$router.push('/result')
          clearInterval(this.timer)
        }
      })
    },
    handleClose() {
      clearInterval(this.timer)
      this.visible = false
    },
  },
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  justify-content: space-between;
  width: 1080px;
  margin: 0 auto;
}
.van-cell--clickable {
  align-items: center;
}
.main-left {
  margin-top: 60px;
  padding: 40px;
  width: 740px;
  min-height: 447px;
  background: #fff;
  box-sizing: border-box;
}
.main-right {
  margin-top: 60px;
  padding: 20px;
  width: 320px;
  background: #fff;
  box-sizing: border-box;
}
.flex {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.van-cell-group img,
.logos img {
  margin-right: 10px;
  height: 40px;
  &.ailpay-logo {
    height: 30px;
  }
}
.van-button {
  width: 80%;
  margin: 40px auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.cell-logos {
  width: 490px;
}
.modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 800px;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 60px;
  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
  }

  .qr-left {
    text-align: center;
    .amount {
      text-align: center;
      margin: 0;
      font-weight: 600;
      font-size: 24px;
    }
    .blue {
      color: #1677ff;
    }
  }
  .qr-right {
    small {
      color: #999;
    }
    h4 {
      font-size: 20px;
    }
    li {
      color: #666;
      font-size: 14px;
      line-height: 2;
    }
  }
  .time {
    display: flex;
    align-items: center;
    justify-content: center;
    .van-icon {
      margin-left: 5px;
      color: #f00;
    }
    .van-count-down {
      margin-left: 5px;
      color: #f00;
    }
  }
}
</style>
